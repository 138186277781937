/* Custom Fonts*/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* Default CSS*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

.content {
  flex-grow: 1;
}

/* Navbar CSS*/
.navbar {
  background-color: #C0C0C0; /* Dull silver background */
  padding: 10px 20px;
  min-height: 10vh; /* Adjusted height for better ratio */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  padding: 0 20px;
}

.navbar-brand {
  margin-right: auto; /* Pushes the logo to the left */
}

.navbar-brand img {
  height: 50px; /* Adjust size as needed */
}

.navbar-collapse {
  margin-left: auto; /* Pushes the button to the right */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-text {
  display: flex;
  align-items: center;
  gap: 15px; /* Spacing between elements */
}

.social-icon img {
  width: 30px;
  height: 30px;
}

.vvd {
  background-color: #E6C07B;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.vvd:hover {
  transform: scale(1.05);
  background-color: #C99A5E;
}

/* Banner Styles */
.banner {
  background: linear-gradient(135deg, #C0C0C0, #E6C07B);
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65vh;
  color: black;
  text-align: left;
}

.banner .container {
  max-width: 80%; /* Prevents overflow into the footer */
  padding-left: 7%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.banner .tagline {
  font-size: 4vw;
  font-weight: bold;
  color: black;
  display: block;
  margin-bottom: 20px;
  line-height: 1.2;
  animation: fadeIn 1s ease-in-out;
}


@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}


.banner p {
  font-size: 1.5vw;
  color: black;
  max-width: 80%; /* Ensures proper text distribution */
  line-height: 1.5;
}

@media (max-width: 768px) {
  .banner .tagline {
    font-size: 6vw; 
  }

  .banner p {
    font-size: 3.5vw;
    max-width: 90%;
  }
}

/* Footer Styles */
.footer {
  background-color: #C0C0C0; /* Dull silver background */
  padding: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  min-height: 30vh;
}

.footer-connect {
  font-size: 2.3vw; /* Increased size */
  color: C0C0C0;
  margin-bottom: 15px;
  font-weight: bold;
}

.footer button {
  background-color: #C0C0C0;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 2vw;
  cursor: pointer;
  transition: 0.3s;
}

.footer button:hover {
  background-color: #a0a0a0;
}

@media (max-width: 768px) {
  .footer button {
    font-size: 4vw; 
    padding: 8px 16px;
  }

  .footer-connect {
    font-size: 4vw;
  }
}

.App {
  text-align: center;
}
